import api from "../../api/api";
//axios.defaults.withCredentials = true;
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const state = {
    all: {
        branch_id: 1,
        organization_id: 1,
    },
    organization: [],
    branch: [],
    warehouse_list: [],
    supplier_list: [],
    branch_list: [],
    users: [],
};

const mutations = {
    UPDATE_ORGANIZATION(state, organization) {
        state.organization = organization;
    },
    UPDATE_STAFF(state, profile) {
        state.users = profile;
    },
    UPDATE_BRANCH(state, branch) {
        state.branch = branch;
    },
    UPDATE_WAREHOUSE_LIST(state, warehouse_list) {
        state.warehouse_list = warehouse_list;
    },
    UPDATE_BRANCH_LIST(state, branch_list) {
        state.branch_list = branch_list;
    },
    UPDATE_SUPPLIER_LIST(state, supplier_list) {
        state.supplier_list = supplier_list;
    },
};

const actions = {
    async fetch_Organization(context) {
        // fetch page layouts and their widget patterns
        let payload = {};
        await api.instance.get("/api/organization").then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = {data: false};
            } else {
                payload = res.data;
            }
            context.commit("UPDATE_ORGANIZATION", payload);
        });
    },
    async fetch_Branch(context) {
        // fetch page layouts and their widget patterns
        let payload = {};
        await api.instance.get("/api/branch").then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = {data: false};
            } else {
                payload = res.data;
            }
            context.commit("UPDATE_BRANCH", payload);
        });
    },
    async fetchBranchByType(context, type) {
        // fetch page layouts and their widget patterns
        let payload = {};
        await api.instance.get("/api/branch/type/" + type).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = {data: false};
            } else {
                payload = res.data;
            }
            if (type == "warehouse") {
                context.commit("UPDATE_WAREHOUSE_LIST", payload);
            } else if (type == "supplier") {
                context.commit("UPDATE_SUPPLIER_LIST", payload);
            } else {
                context.commit("UPDATE_BRANCH_LIST", payload);
            }
        });
    },
    async updateOrganization(context, organization) {
        await api.instance
            .put("/api/organization/" + organization.id, {organization})
            .then((res) => {
                context.commit("UPDATE_ORGANIZATION", res.data);
            });
    },
    async fetchStaff({commit}) {
        await api.instance.get("/api/organization/staff").then((res) => {
            commit("UPDATE_STAFF", res.data);
        });
    },
};

const getters = {
    getWarehouse: (state) => state.warehouse_list,
    getBranch: (state) => state.branch_list,
    getSupplier: (state) => state.supplier_list,
    getOrganization: (state) => state.organization,
    users: (state) => state.users,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
