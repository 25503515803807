import axios
    from "axios";
import { API_URL } from "./constants";
import store
    from '@/store/store';
import md5 from 'md5'; // You can use a library like md5 to generate a unique hash
import { Notyf } from "notyf";


const instance = axios.create({
    baseURL: API_URL,
    timeout: 500000,
    withCredentials: true,
});

const url_secure_list = ['/api/stock','/api/stock/scanToUpdate','/api/order/complete_distribution','/api/order/complete_packing','/api/refund/issue_refund','/api/order/confirm_payment'];
const url_secure_type = ['post','put','patch','delete'];

// Add a request interceptor
instance.interceptors.request.use(
    config => {
        // Generate a unique hash of the request payload
        if (config.data !== undefined) {
            const requestHash = md5(JSON.stringify(config.data));
            // console.log(config.url, url_secure_list);
            // console.log(config.method, url_secure_type);
            // console.log(requestHash);
            // Check if the request has already been sent
            if ((url_secure_type.includes(config.method) && url_secure_list.includes(config.url)) && (localStorage.getItem('REQUEST_HASH') !== null && localStorage.getItem('REQUEST_HASH') === requestHash)) {
                // Request already sent, prevent duplicate request by returning a rejected Promise
                var notyf = new Notyf();
                notyf.success({
                    message: "Duplicate request, please wait for the previous request to finish or modify the request payload!",
                    background: "red",
                });
                return Promise.reject('Duplicate request');

            }
            localStorage.setItem('REQUEST_HASH', requestHash);
        }



        if (config.baseURL.toLowerCase() === API_URL.toLowerCase() && config.url.includes('api/') && !store.getters["Search/loading"]) {
            store.dispatch("Search/setLoading", true)
        }
        if (store.getters['Profile/authenticated'] || localStorage.getItem('token')) {
            config.headers = {
                'Authorization': `Bearer ${store.getters['Profile/accessToken'] ?? localStorage.getItem('token')}`,
                'Accept': 'application/json',
            }
        }
        return config;
    },
    error => {
        if (store.getters["Search/loading"]) {
            store.dispatch("Search/setLoading", false)
        }
        return Promise.reject(error)
    }
);

instance.interceptors.response.use(
    response => {
        if (store.getters["Search/loading"]) {
            store.dispatch("Search/setLoading", false)
            // if (localStorage.getItem('access_time_from') != null && localStorage.getItem('access_time_to') != null) {
            //     const date1 = new Date();
            //     const date2 = new Date();
            //     const date3 = new Date();

            //     date1.setHours(parseInt(localStorage.getItem('access_time_from').split(":")[0]));
            //     date1.setMinutes(parseInt(localStorage.getItem('access_time_from').split(":")[1]));

            //     date2.setHours(parseInt(localStorage.getItem('access_time_to').split(":")[0]));
            //     date2.setMinutes(parseInt(localStorage.getItem('access_time_to').split(":")[1]));
            //     const hours = date3.getHours();
            //     const minutes = date3.getMinutes();

            //     if ((hours < date1.getHours() || (hours === date1.getHours() && minutes <= date1.getMinutes())) || (hours > date2.getHours() || (hours === date2.getHours() && minutes >= date2.getMinutes()))) {
            //         localStorage.setItem('timeout_message', 'Your access time is only valid from ' + localStorage.getItem('access_time_from') + ' till ' + localStorage.getItem('access_time_to') + '. Please contact your administrator for assistance.');
            //         localStorage.removeItem('token');
            //         localStorage.removeItem('vuex');
            //         localStorage.removeItem('access_time_to');
            //         localStorage.removeItem('access_time_from');
            //         store.dispatch('Profile/logout');
            //         window.location.replace("/");
            //     }
            // }
        }
        return response;
    },
    (error) => {
        if (error.response && error.response.status == 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('vuex');
            store.dispatch('Profile/logout');
            window.location.replace("/");
        }
        if (store.getters["Search/loading"]) {
            store.dispatch("Search/setLoading", false)
        }
        return Promise.reject(error);
    }
);


export default {
    namespaced: true,
    instance,
};
