import api from "@/api/api";

export default {

    namespaced: true,

    state: {
        files: []
    },

    getters: {
        files(state){
            return state.files
        }
    },

    mutations: {
        SET_FILES(state, payload){
            state.files = payload
        },
        ADD_FILE(state, payload){
            state.files = [payload, ...state.files]
        },
        REMOVE_FILE(state, payload){
            state.files = state.files.filter(file => file.uuid !== payload)
        }
    },

    actions: {
        async getFiles(context){
            await api.instance.get('/api/files').then((res) => {
                context.commit("SET_FILES", res.data.data)
            })
        },
        async addFile(context, payload){
            console.log(payload)
            context.commit("SET_FILES", payload)
        },
        async uploadFile(context, payload) {
            await api.instance.post("/api/files", payload, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
            }).then((res) => {
                context.commit("ADD_FILE", res.data);
            });
        },
        async deleteFile(context, uuid){
            await api.instance.delete(`/api/files/${uuid}`).then(() => {
                context.commit("REMOVE_FILE", uuid)
            })
        }
    }

}