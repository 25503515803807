import Vue
    from "vue";
import Router
    from "vue-router";
import goTo
    from "vuetify/es5/services/goto";
import store
    from "./store/store";

import guest from "@/middleware/guest";

import auth from "@/middleware/auth";

import middlewarePipeline from "@/middlewarePipeline";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    // This is for the scroll top when click on any router link
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },
    routes: [
        {
            // main application - authenicated view
            path: "/",
            redirect: "dashboard",
            component: () => import("@/layouts/erp-layout/Layout"),
            children: [
                {
                    name: "chat",
                    path: "dashboard",
                    component: () => import("@/views/dashboard"),
                    meta:{
                        middleware: [auth]
                    },
                },
                {
                    name: "support",
                    path: "inbox",
                    component: () =>
                        import("@/views/components/extensions/support/tickets"),
                    meta:{
                        middleware: [auth]
                    },
                },

                {
                    name: "Tutorials",
                    path: "howTo",
                    component: () =>
                        import("@/views/components/extensions/shared/howto"),
                    meta:{
                        middleware: [auth]
                    },
                },
                {
                    name: "workflow",
                    path: "stock/:order/:workflow_state",
                    component: () =>
                        import("@/views/components/extensions/catalogue/stockManager"),
                    meta:{
                        middleware: [auth]
                    },
                },
                {
                    name: "settings",
                    path: "settings",
                    component: () =>
                        import("@/views/components/extensions/profile/AccountSettings"),
                    meta:{
                        middleware: [auth]
                    },
                },
                {
                    name: "inventory",
                    path: "inventory",
                    component: () =>
                        import("@/views/components/extensions/shared/InventoryScanned"),
                    meta:{
                        middleware: [auth]
                    },
                },
                {
                    name: "inventory",
                    path: "import_stock",
                    component: () =>
                        import("@/views/components/extensions/suppliers/importStock"),
                    meta:{
                        middleware: [auth]
                    },
                },
                {
                    name: "scanToCount",
                    path: "scanToCount",
                    component: () =>
                        import(
                            "@/views/components/extensions/catalogue/scanToCountCart.vue"
                            ),
                    meta:{
                        middleware: [auth]
                    },
                },
                {
                    name: "orders",
                    path: "orders",
                    component: () =>
                        import("@/views/components/extensions/shared/OrdersScanned"),
                    meta:{
                        middleware: [auth]
                    },
                },
                {
                    name: "session",
                    path: "session",
                    component: () =>
                        import("@/views/components/extensions/shared/shiftClosed"),
                    meta:{
                        middleware: [auth]
                    },
                },
            ],
        },
        {
            // warehouse
            path: "/api",
            component: () => import("@/layouts/erp-layout/Layout"),
            children: [
                {
                    name: "ext-warehouse",
                    path: ":section/:item",
                    component: () => import("@/views/dynamic"),
                    meta:{
                        middleware: [auth]
                    },
                },
                {
                    name: "support",
                    path: ":section/:item/:mailType",
                    component: () =>
                        import("@/views/components/extensions/support/tickets"),
                    children: [
                        {
                            path: ":id",
                            name: "MailIndex",
                            component: () =>
                                import("@/views/components/extensions/support/viewTicket.vue"),
                            meta:{
                                middleware: [auth]
                            },
                        },
                    ],
                },
            ],
        },
        {
            // admin
            path: "/Admin",
            component: () => import("@/layouts/erp-layout/Layout"),
            children: [
                {
                    name: "ext-admin",
                    path: ":section",
                    component: () => import("@/views/dynamic"),
                    meta:{
                        middleware: [auth]
                    },
                },
            ],
        },
        // in-authenticated view
        {
            path: "/auth",
            redirect: "/auth/login",
            component: () => import("@/layouts/blank-layout/Blanklayout"),
            children: [
                {
                    name: "Login",
                    path: "login",
                    component: () => import("@/views/authentication/FullLogin"),
                    meta:{
                        middleware: [guest]
                    },
                },
                {
                    name: "Register",
                    path: "register",
                    component: () =>
                        import("@/views/template/authentication/FullRegister"),
                    meta:{
                        middleware: [guest]
                    },
                },
            ],
        },
    ],
});

// import NProgress from "nprogress";

router.beforeEach((to, from, next) => {

    if(!to.meta.middleware){
        return next()
    }

    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        store,
        next
    }

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    })

})

// router.beforeResolve((to, from, next) => {
//     // If this isn't an initial page load.
//     if (to.name) {
//         // Start the route progress bar.
//         NProgress.start(800);
//     }
//     next();
// });
//
// router.afterEach(() => {
//     // Complete the animation of the route progress bar.
//     NProgress.done();
// });

export default router;
