export default {

    namespaced: true,

    state: {
        searchString: '',
        loading: false,
        searchLoading: false
    },

    getters: {
        loading(state){
            return state.loading
        },
        searchLoading(state){
            return state.searchLoading
        },
        searchKeyword(state){
            return state.searchString
        }
    },

    mutations: {
        SET_LOADING(state, payload){
            state.loading = payload;
        },

        SET_SEARCH_LOADING(state, payload){
            state.searchLoading = payload;
        },

        SET_SEARCH_KEYWORD(state, payload){
            state.searchString = payload;
        }
    },

    actions: {
        setLoading({ commit }, value){
            commit("SET_LOADING", value);
        },

        setSearchLoading({ commit }, value){
            commit("SET_SEARCH_LOADING", value);
        },

        setSearchKeyword(context, value){
            context.commit("SET_SEARCH_KEYWORD", value);
            context.commit("SET_SEARCH_KEYWORD", '')
        },

        unsetSearchKeyword({ commit }, value){
            commit("SET_SEARCH_KEYWORD", value);
        }
    }

}
