import api from "../../api/api";


const state = {

    announcements:[],
    show_announcement_dialog:true,
    manage_annoucements:[],

}

const mutations = {
    
    get_announcements(state, payload) {
        state.announcements = payload;
    },
    get_managed_announcements(state, payload){
        state.manage_annoucements = payload;
    },
    update_Dialog_state(state, payload){
        state.show_announcement_dialog = payload;
    }
  
};

const actions = {

    async createAnnouncement(context, payload) {
      
        await api.instance.post("/api/announcement", payload).then((res) => {
            console.log('response:', res);
            // Handle response as needed
        }).catch((error) => {
            console.error('API request failed:', error);
            // Handle error
        });
    },


    async updateAnnouncement(context,payload){

        await api.instance.post("/api/announcement/update",payload).then((res) => {
            console.log(res)
        }).catch((error)=>{
            console.error('API request failed:', error);
        });

    },

    async deleteAnnouncement(context,payload){

        await api.instance.post("/api/announcement/delete",payload).then((res) => {
            console.log(res)
        }).catch((error)=>{
            console.error('API request failed:', error);
        });

    },





    //async getAnnouncement(context, payload) {
    async getAnnouncement(context) {

        await api.instance.get("/api/announcement").then((res) => {
            context.commit("get_announcements",res.data);
        });
    },

    async getManagedAnnouncements(context){

        await api.instance.get("/api/announcement/getManagedAnnouncements").then((res) => {
    
            context.commit("get_managed_announcements",res.data);

        }).catch((error)=>{
            console.error('API request failed:', error);
        });

    },

    //update dialog state
    async updateDialogState(context){
      
        context.commit("update_Dialog_state",false);
        localStorage.setItem('dialogShow','false')
        
    }

};

const getters = {

    get_announcements: (state) => state.announcements,
    get_managed_announcements: (state) => state.manage_annoucements,
    show_announcement_dialog: (state) => state.show_announcement_dialog,

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
