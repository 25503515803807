import api from "../../api/api";
//import { API_URL } from "../../api/constants";
//import axios from "axios";
//axios.defaults.withCredentials = true;
// axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const initialState = () => {
    return {
        all: {},
        catalogue: false,
        inventory: false,
        order: false,
        cataloqueTypes: false,
        CategoriesInStock: false,
        CategoriesInStockForOtherGroup: false,
        scannedInventory: false,
        scannedOrder: false,
        floorplan: false,
        stockfloorplans: false,
        floorplan_stock: false,
    }
}

const state = {
    initialState: initialState,
    all: {},
    catalogue: false,
    inventory: false,
    order: false,
    cataloqueTypes: false,
    CategoriesInStock: false,
    CategoriesInStockForOtherGroup: false,
    scannedInventory: false,
    scannedOrder: false,
    floorplan: false,
    stockfloorplans: false,
    floorplan_stock: false,
    shortInventoryStock:[],
};

const mutations = {
    UPDATE_CATALOGUE(state, catalogue) {
        state.catalogue = catalogue;
    },
    UPDATE_INVENTORY(state, inventory) {
        state.inventory = inventory;
    },
    UPDATE_ORDER(state, order) {
        state.order = order;
    },
    UPDATE_CATALOGUETYPES(state, order) {
        state.cataloqueTypes = order;
    },
    UPDATE_CATALOGUE_INSTOCK(state, order) {
        state.CategoriesInStock = [];
        order.forEach((e) => {
            //console.log('items');
            if (e.catalogue_type !== null) {
                state.CategoriesInStock.push(e.catalogue_type);
            }

        });
    },
    UPDATE_CATALOGUE_INSTOCK_FOROTHERS(state, order) {
        state.CategoriesInStockForOtherGroup = [];
        if (order != false) {
            order.forEach((e) => {
                if (e.catalogue_type !== null) {
                    state.CategoriesInStockForOtherGroup.push(e.catalogue_type);
                }
            });
        }
    },
    scannedInventory(state, payload) {
        state.scannedInventory = payload;
    },
    update_floorplan(state, payload) {
        state.floorplan = payload;
    },
    update_stockfloorplans(state, payload) {
        state.stockfloorplans = payload;
    },
    update_floorplan_stock(state, payload) {
        state.floorplan_stock = payload;
    },
    scannedOrder(state, payload) {
        state.scannedOrder = payload;
    },

    
    shortInventoryStock(state,payload){
        state.shortInventoryStock = payload;
    },
};

const actions = {
    async fetch_Catalogue(context) {
        // fetch page layouts and their widget patterns
        let payload = {};
        await api.instance.get("/api/catalogue").then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = {context: false, widgets: false};
            } else {
                payload = res.data;
            }
            context.commit("UPDATE_CATALOGUE", payload);
        });
    },
    async CategoriesInStock(context) {
        // fetch page layouts and their widget patterns
        let payload = {};
        await api.instance.get("/api/catalogue/CategoriesInStock").then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = [];
            } else {
                payload = res.data;

                context.commit("UPDATE_CATALOGUE_INSTOCK", payload);
            }
        });
    },
    async CategoriesInStockForOtherGroup(context, id) {
        // fetch page layouts and their widget patterns
        let payload = {};
        await api.instance
            .get("/api/catalogue/CategoriesInStockForOtherGroup/" + id)
            .then((res) => {
                if (!res.data || res.data.length == 0) {
                    payload = false;
                } else {
                    payload = res.data;
                }
                context.commit("UPDATE_CATALOGUE_INSTOCK_FOROTHERS", payload);
            });
    },
    async fetchCatalogueTypes(context) {
        // fetch page layouts and their widget patterns
        let payload = {};
        await api.instance.get("/api/catalogue/fetchCatalogueTypes").then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = {context: false, widgets: false};
            } else {
                payload = res.data;
            }
            context.commit("UPDATE_CATALOGUETYPES", payload);
        });
    },
    async fetch_Inventory(context) {
        // fetch page layouts and their widget patterns
        let payload = {};

        await api.instance.get("/api/inventory").then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = {context: false, widgets: false};
            } else {
                payload = res.data;
            }
            context.commit("UPDATE_INVENTORY", payload);
        });
    },
    async manage_order(context, order) {
        let payload = {};
        await api.instance.get("/api/order/manage_order/" + order).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = {context: false, widgets: false};
            } else {
                payload = res.data;
            }
            context.commit("UPDATE_ORDER", payload);
        });
    },
    async scannedOrder(context, order) {
        let payload = {};
        await api.instance.get("/api/order/get_by_id/" + order).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = {context: false, widgets: false};
            } else {
                payload = res.data;
            }
            context.commit("scannedOrder", payload);
        });
    },

    async scannedInventory(context, order) {

        
        let payload = {};
        let in_stock_only = context.rootState.Pos.in_stock_only;
        let active_company = context.rootState.Pos.active_company.id;

        if (!active_company || active_company == 0) {
            active_company = context.rootState.Profile.all.profile.get_branch[0].id

        }
        if(context.rootState.Pos.oe_mode){

            order=order.substring(0,9);
            
        }

        await api.instance.post("/api/inventory/system_number/" + order, {
      
            in_stock_only: in_stock_only,
            active_company: active_company
        }).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
          
            context.commit("scannedInventory", payload);
        });
    },

    
    async shortInventoryStock(context){
        
        await api.instance.post("/api/inventory/getShortStockInventory/").then((res) => {
            console.log('response22',res.data)
            context.commit("shortInventoryStock",res.data)
        });

    },

    async fetch_floorplan(context, branch_id) {
        let payload = {};
        await api.instance.get("/api/floorplans/" + branch_id).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_floorplan", payload);
        });
    },
    async fetch_stockfloorplans(context, branch_id) {
        let payload = {};
        await api.instance.get("/api/stockfloorplans/" + branch_id).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_stockfloorplans", payload);
        });
    },
    async fetchStockByLocation(context, location) {
        let payload = {};
        await api.instance
            .get("/api/layouts/fetchStockByLocation/" + location)
            .then((res) => {
                if (!res.data || res.data.length == 0) {
                    payload = false;
                } else {
                    payload = res.data;
                }
                context.commit("update_floorplan_stock", payload);
            });
    },
};

const getters = {
    getCatalogue: (state) => state.catalogue,
    getInventory: (state) => state.inventory,
    getOrder: (state) => state.order,
    getCatalogueTypes: (state) => state.cataloqueTypes,
    CategoriesInStock: (state) => state.CategoriesInStock,
    scannedOrder: (state) => state.scannedOrder,
    scannedInventory: (state) => state.scannedInventory,
    CategoriesInStockForOtherGroup: (state) =>
        state.CategoriesInStockForOtherGroup,
    floorplan: (state) => state.floorplan,
    floorplan_stock: (state) => state.floorplan_stock,
    stockfloorplans: (state) => state.stockfloorplans,
    shortInventoryStock: (state) => state.shortInventoryStock,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
