import api
    from "../../api/api";

const initialState = () => {
    return {
        suppliers: [],
        pagination: {
            page: 0,
            rowsPerPage: 0,
        },
        totalItems: 0
    }
}

const state = {
    initialState: initialState,
    suppliers: [],
    pagination: {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    },
    totalItems: null,
};

const mutations = {
    FETCH_SUPPLIERS(state, payload) {
        state.suppliers = payload.data.data;
        state.pagination = {
            current_page: payload.current_page,
            from: payload.from,
            last_page: payload.last_page,
            per_page: payload.per_page,
            to: payload.to,
            total: payload.total,
        };
        state.totalItems = payload.total;
    },
    CREATE_SUPPLIER(state, payload) {
        state.suppliers = payload.data.data;
        state.pagination = {
            current_page: payload.current_page,
            from: payload.from,
            last_page: payload.last_page,
            per_page: payload.per_page,
            to: payload.to,
            total: payload.total,
        };
        state.totalItems = payload.total;
    },
    UPDATE_SUPPLIER(state, payload) {
        state.suppliers = payload.data.data;
        state.pagination = {
            current_page: payload.current_page,
            from: payload.from,
            last_page: payload.last_page,
            per_page: payload.per_page,
            to: payload.to,
            total: payload.total,
        };
        state.totalItems = payload.total;
    },
    DELETE_SUPPLIER(state, payload) {
        state.suppliers = state.suppliers.filter(supplier => supplier.id !== payload)
    },
};

const actions = {
    async searchSuppliers(context, payload) {
        console.log(payload)
        await api.instance.post(`/api/suppliers/search`, payload)
            .then((res) => {
                context.commit("FETCH_SUPPLIERS", res);
            });
    },
    // async fetchInventories(context, id) {
    //     await api.instance.get(`/api/suppliers/${id}`)
    //         .then((res) => {
    //             context.commit("UPDATE_SUPPLIER", res);
    //         });
    // },
    async fetchSuppliers(context) {
        await api.instance.get(`/api/suppliers`)
            .then((res) => {
                context.commit("FETCH_SUPPLIERS", res);
            });
    },
    async fetchPaginatedSuppliers(context, data) {
        let page_number = data.pagination.to;
        await api.instance.get(`/api/suppliers?page=${page_number}`)
            .then((res) => {
                context.commit("FETCH_SUPPLIERS", res);
            });
    },
    async createSupplier(context, data) {
        await api.instance.post("/api/suppliers", data)
            .then((res) => {
                console.log(res, res.data)
                context.commit("CREATE_SUPPLIER", res);
            });
    },
    async updateSupplier(context, data) {
        await api.instance.put(`/api/suppliers/${data.id}`, data)
            .then((res) => {
                context.commit("UPDATE_SUPPLIER", res);
            });
    },
    async deleteSupplier(context, id) {
        await api.instance.delete(`/api/suppliers/${id}`)
            .then(() => {
                context.commit("DELETE_SUPPLIER", id);
            });
    },
};

const getters = {
    suppliers: (state) => state.suppliers,
    pagination: (state) => state.pagination,
    totalItems: (state) => state.totalItems,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};